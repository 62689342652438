import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { resourceTypes } from '@Utils/resource-util';
import { firstItemIdInGroup } from '@State/action-helpers';
import { fetchGroupsAndResources } from '@State/resource-actions';
import { getResourceConfigUrl } from '@Utils/navigate';
import { fetchAllPosUnits } from '@State/pos-config-actions';
import { toggleResourcesSortable } from '@State/admin-view-reducer';
import { CHANGE_RESOURCES, hasPermission } from '@Utils/permissions';
import { getPermissions, getSysAdmin, getWebPaymentEnabled } from '@State/selectors';
import ResourcesSidebar from '@Components/resources/resources-sidebar';
import ResourceContent from '@Components/resources/resource-content';
import Loader from '@Components/ui/loader';

class Resources extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    resourceId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    const { paymentEnabled, fetchAllPosUnits, fetchGroupsAndResources } = this.props;
    Promise.all([
      fetchGroupsAndResources(),
      paymentEnabled && fetchAllPosUnits()
    ]).then(() => this.setState({ loading: false }));
  }

  componentWillUnmount() {
    if (this.props.sortable) {
      this.props.toggleSortable(false);
    }
  }

  handleToggleSorting = ev => {
    this.props.toggleSortable(!this.props.sortable);
  };

  render() {
    const { loading } = this.state;
    const {
      routeParams, resourceId, resource, resourcesById,
      orderedGroups, permissions, sortable
    } = this.props;
    const canChangeResource = hasPermission(permissions, CHANGE_RESOURCES);
    const isNotPerson = resource?.resourceType !== resourceTypes.Person;

    if (loading && resourcesById.isEmpty()) {
      return <Loader />;
    }

    if (!resourcesById.isEmpty() && (!resourceId || (resourceId && !resource))) {
      const firstItemId = firstItemIdInGroup(orderedGroups, 'resourceIds');
      return (
        <Redirect to={getResourceConfigUrl(routeParams, firstItemId)} />
      );
    }

    if (isNotPerson && routeParams.tab === 'services') {
      return (
        <Redirect to={getResourceConfigUrl(routeParams, resourceId)} />
      );
    }

    return (
      <div className="columns-container">
        {canChangeResource && (
          <ResourcesSidebar
            routeParams={routeParams}
            permissions={permissions}
            sortable={sortable}
            onToggleSorting={this.handleToggleSorting}
          />
        )}
        <ResourceContent routeParams={routeParams} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourcesById, orderedGroups, adminViewState } = state;
  const resourceId = parseInt(ownProps.match.params.id);
  const routeParams = {
    ...ownProps.match.params,
    entityId: resourceId,
    entityType: 'resource'
  };
  const resource = resourcesById && resourceId && resourcesById.get(resourceId);

  return {
    isSysAdmin: getSysAdmin(state),
    resourceId,
    resource,
    resourcesById,
    orderedGroups,
    routeParams,
    permissions: getPermissions(state, { routeParams }),
    sortable: adminViewState.get('resourcesSortable'),
    paymentEnabled: getWebPaymentEnabled(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllPosUnits: () => dispatch(fetchAllPosUnits()),
    fetchGroupsAndResources: () => dispatch(fetchGroupsAndResources()),
    toggleSortable: (sortable) => dispatch(toggleResourcesSortable(sortable))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resources);
