import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resourceTypes } from '@Utils/resource-util';
import { getChangeJsonPrefsPermission, getFeatures } from '@State/selectors';
import NavTabsLink from '@Components/ui/nav-tabs-link';

class ResourceSubNav extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    locationFeatures: PropTypes.object.isRequired
  };

  sections() {
    const { resourceType, features, canChangeJsonPrefs } = this.props;
    const { ShowGeneralSettings, ShowServicesSettings } = features;
    const isPerson = resourceType === resourceTypes.Person;
    const sections = [];

    if (ShowGeneralSettings) {
      sections.push({ navId: 'settings', name: 'Inställningar' });
    }
    if (ShowServicesSettings && isPerson) {
      sections.push({ navId: 'services', name: 'Tjänster' });
    }
    if (canChangeJsonPrefs) {
      sections.push({ navId: 'prefs', name: 'Prefs' });
    }
    return sections;
  }

  render() {
    const { routeParams } = this.props;
    return (
      <NavTabsLink
        subUrl="admin/resources"
        routeParams={routeParams}
        sections={this.sections()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    canChangeJsonPrefs: getChangeJsonPrefsPermission(state, ownProps),
    resourceType: ownProps.resource?.resourceType,
    features: getFeatures(state)
  };
};

export default connect(mapStateToProps)(ResourceSubNav);
