export const CREATE_BOOKING = 'CREATE_BOOKING';
export const CONFIRM_BOOKING = 'CONFIRM_BOOKING';
export const MOVE_BOOKING = 'MOVE_BOOKING';
export const CHANGE_BOOKING_DETAILS = 'CHANGE_BOOKING_DETAILS';
export const CHANGE_BOOKING_STATUS = 'CHANGE_BOOKING_STATUS';
export const CHANGE_BOOKING_ATTRIBUTES = 'CHANGE_BOOKING_ATTRIBUTES';
export const CHANGE_BOOKING_CUSTOM_FIELDS = 'CHANGE_BOOKING_CUSTOM_FIELDS';
export const ADD_BOOKING_CUSTOMER = 'ADD_BOOKING_CUSTOMER';
export const DELETE_BOOKING_CUSTOMER = 'DELETE_BOOKING_CUSTOMER';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const REFUND_BOOKING = 'REFUND_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const DELETE_UNCONFIRMED_BOOKING = 'DELETE_UNCONFIRMED_BOOKING';
export const BOOKING_SERIES_CREATED = 'BOOKING_SERIES_CREATED';
export const MODIFY_CUSTOMER = 'MODIFY_CUSTOMER';
export const CHANGE_PREFS = 'CHANGE_PREFS';
export const PAYMENT_PROGRESS = 'PAYMENT_PROGRESS';
export const PAYMENT_RESULT = 'PAYMENT_RESULT';
export const PRINTER_QUEUED = 'PRINTER_QUEUED';
export const PRINTER_PROGRESS = 'PRINTER_PROGRESS';
export const PRINTER_DONE = 'PRINTER_DONE';
export const PRINTER_STATUS = 'PRINTER_STATUS';
export const OPEN_VUNIT = 'OPEN_VUNIT';
export const SWITCH_VUNIT_DEVICE_TOKEN = 'SWITCH_VUNIT_DEVICE_TOKEN';
export const CLOSE_VUNIT = 'CLOSE_VUNIT';
export const INVOICE_STATUS = 'INVOICE_STATUS';
