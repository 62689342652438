import moment from 'moment';
import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTerminalStatus } from '@State/pos-config-actions';

class PosTerminalStatusUpdate extends Component {
  constructor(props) {
    super(props);

    this.timeout = 10;
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer = () => {
    this.lastUpdate = moment();
    this.checkInterval = setInterval(this.checkTimeout, 5000);
  };

  stopTimer = () => {
    clearInterval(this.checkInterval);
  };

  onUpdate = () => {
    this.lastUpdate = moment();
  };

  checkTimeout = () => {
    const { vendorId } = this.props;
    const timeoutLimit = moment().subtract(this.timeout, 'seconds');

    if (this.lastUpdate < timeoutLimit) {
      this.props.fetchTerminalStatus(vendorId)
        .then(this.onUpdate)
        .catch(this.stopTimer);
    }
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTerminalStatus: (vendorId) => dispatch(fetchTerminalStatus(vendorId))
  };
};

export default connect(null, mapDispatchToProps)(PosTerminalStatusUpdate);
