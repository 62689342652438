import { fetchBooking, fetchBookings } from '@State/booking-actions';
import { isBookingInView } from './booking-util';
export function getEntityId({ entityId }) {
    return /^\d+$/.test(entityId) ? parseInt(entityId) : entityId;
}
export function onPusherError(error) {
    console.error(error);
    window.Sentry.captureException(error);
}
export function fetchBookingData(event) {
    return (dispatch, getState) => {
        try {
            const { resources, startTime } = event.data;
            const resourceIds = resources.map(resource => resource.id);
            if (isBookingInView(getState(), resourceIds, startTime)) {
                return dispatch(fetchBooking(getEntityId(event), true));
            }
            else {
                console.info('Skipping fetching booking data for event', event);
            }
        }
        catch (error) {
            console.error('Failed to fetch booking data for event', event, error);
        }
        return Promise.resolve(null);
    };
}
export function fetchBookingSeriesData(event) {
    return (dispatch, getState) => {
        try {
            const state = getState();
            const { bookings, resourceIds } = event.data;
            const bookingIds = bookings
                .filter(b => isBookingInView(state, resourceIds, b.startTime))
                .map(booking => booking.id);
            if (bookingIds.length > 0) {
                return dispatch(fetchBookings(bookingIds));
            }
            else {
                console.info('Skipping fetching booking data for event', event);
            }
        }
        catch (error) {
            console.error('Failed to fetch booking data for event', event, error);
        }
        return Promise.resolve(null);
    };
}
