import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@Components/ui/styled/variables';
import PosTerminalStatusUpdate from './pos-terminal-status-update';

const StatusIcon = styled.i`
  margin-right: 6px;
  vertical-align: baseline;

  ${props => props.small && css`
    font-size: 11px;
  `}
  ${props => props.error && css`
    color: ${colors.danger};
  `}
  ${props => props.online && css`
    color: ${colors.statusGreen};
  `}
  ${props => props.progress && css`
    color: ${colors.darkGray80};
  `}
  ${props => props.offline && css`
    color: ${colors.darkGray30};
  `}
`;

const PosTerminalStatusIcon = ({ socketOnline, deviceStatus, ...props }) => {
  const terminalDisconnected = deviceStatus === 'Disconnected';
  const terminalReady = deviceStatus === 'Ready';
  const terminalBusy = deviceStatus === 'Busy';

  if (socketOnline && terminalReady) {
    return <StatusIcon online {...props} className="fa fa-fw fa-circle" />;
  }
  if (socketOnline && terminalDisconnected) {
    return <StatusIcon error {...props} className="fa fa-fw fa-circle" />;
  }
  if (socketOnline && terminalBusy) {
    return <StatusIcon progress {...props} className="fa fa-fw fa-circle-notch fa-spin" />;
  }
  return <StatusIcon offline {...props} className="fa fa-fw fa-circle" />;
};

const PosTerminalStatus = ({ terminal, ...props }) => {
  const { deviceStatus, connectionStatus } = terminal.get('terminalStatus').toObject();
  const socketOnline = connectionStatus === 'Online';

  return (
    <>
      <PosTerminalStatusIcon
        socketOnline={socketOnline}
        deviceStatus={deviceStatus}
        {...props}
      />
      {socketOnline && (
        <PosTerminalStatusUpdate vendorId={terminal.get('vendorId')} />
      )}
    </>
  );
};

export default PosTerminalStatus;
