import axios from 'axios';
import {
  axiosDefault, axiosErrorHandler, checkStatusAxios, prefixUrl
} from '@Utils/ajax-util';
import { fetchAvailablePosUnits } from '@State/pos-actions';
import { getPosUnitPrefsFromVunitId } from './pos-selectors';

export const POS_PROGRESS = 'POS_PROGRESS';
export const POS_CREATE_ORG = 'POS_CREATE_ORG';
export const POS_ORG_UPDATED = 'POS_ORG_UPDATED';
export const POS_ORG_DELETED = 'POS_ORG_DELETED';
export const POS_ORGS_FETCHED = 'POS_ORGS_FETCHED';
export const POS_CONTACTS_FETCHED = 'POS_CONTACTS_FETCHED';
export const POS_CONTACT_UPDATED = 'POS_CONTACT_UPDATED';
export const POS_ORG_UNITS_FETCHED = 'POS_ORG_UNITS_FETCHED';
export const POS_ALL_UNITS_FETCHED = 'POS_ALL_UNITS_FETCHED';
export const POS_UNIT_CREATED = 'POS_UNIT_CREATED';
export const POS_UNIT_UPDATED = 'POS_UNIT_UPDATED';
export const POS_UNIT_DELETED = 'POS_UNIT_DELETED';
export const POS_TERMINAL_BUSY = 'POS_TERMINAL_BUSY';
export const POS_TERMINAL_READY = 'POS_TERMINAL_READY';
export const POS_TERMINAL_STATUS = 'POS_TERMINAL_STATUS';
export const POS_TERMINAL_UPDATED = 'POS_TERMINAL_UPDATED';
export const POS_TERMINAL_ASSOCIATED = 'POS_TERMINAL_ASSOCIATED';
export const POS_TERMINAL_DISASSOCIATED = 'POS_TERMINAL_DISASSOCIATED';
export const POS_TERMINAL_REFDATA_FETCHED = 'POS_TERMINAL_REFDATA_FETCHED';
export const POS_TERMINALS_BY_UNIT_FETCHED = 'POS_TERMINALS_BY_UNIT_FETCHED';
export const POS_PRINTERS_FETCHED = 'POS_PRINTERS_FETCHED';
export const POS_PRINTER_QUEUED = 'POS_PRINTER_QUEUED';
export const POS_PRINTER_PROGRESS = 'POS_PRINTER_PROGRESS';
export const POS_PRINTER_DONE = 'POS_PRINTER_DONE';
export const POS_PRINTER_STATUS = 'POS_PRINTER_STATUS';

const getTerminalsUrl = (path) => prefixUrl(`/payments/pos/terminals${path}`);

function setPosProgress(progress) {
  return {
    type: POS_PROGRESS,
    progress
  };
}

export function createOrg(data) {
  return dispatch => {
    const url = prefixUrl('/pos/orgs/');
    const config = axiosDefault();

    return axios
      .post(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ id }) => {
        const org = { ...data, id };
        dispatch({ type: POS_CREATE_ORG, org });
        return org;
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function getEcomData({ companyName, companyOrgNo, vatRegNo, companyAddress, partnerId }) {
  return {
    companyName,
    companyOrgNo,
    vatRegNo,
    address: companyAddress,
    partnerId: partnerId ? parseInt(partnerId) : null
  };
}

export function createPosOrg(data) {
  return dispatch => {
    const isEcom = data.vunitType === 'Ecom';
    const url = prefixUrl(isEcom ? '/pos/vunits/ecom/' : '/pos/create-pos/');
    const config = axiosDefault();

    return axios
      .post(url, isEcom ? getEcomData(data) : data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ posOrgId }) => {
        const org = { ...data, id: posOrgId };
        dispatch({ type: POS_CREATE_ORG, org });
        return org;
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function fetchPosOrg(orgId) {
  return dispatch => {
    const url = prefixUrl(`/pos/orgs/${orgId}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then((res) => dispatch({ type: POS_ORG_UPDATED, org: res.data }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateOrg(org, orgId) {
  return dispatch => {
    const url = prefixUrl(`/pos/orgs/${orgId}`);
    const config = axiosDefault();

    const {
      companyName, companyOrgNo, vatRegNo, companyAddress
    } = org;
    const data = {
      companyName,
      companyOrgNo,
      vatRegNo,
      address: companyAddress
    };

    return axios
      .put(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_ORG_UPDATED, org }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deletePosOrg(id) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/orgs/${id}/`);
    const config = axiosDefault();

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then((res) => dispatch({ type: POS_ORG_DELETED, id }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateOrgContacts(posOrgId, contacts) {
  return dispatch => {
    const {
      ownerName, ownerEmail, ownerPhone, enableBilling,
      billingName, billingEmail, billingPhone
    } = contacts;
    const ownerContact = { name: ownerName, email: ownerEmail, phone: ownerPhone };
    const billingContact = { name: billingName, email: billingEmail, phone: billingPhone };

    return Promise.all([
      dispatch(saveContactInfo(posOrgId, 'Owner', ownerContact)),
      enableBilling
        ? dispatch(saveContactInfo(posOrgId, 'Billing', billingContact))
        : dispatch(deleteContactInfo(posOrgId, 'Billing'))
    ]);
  };
}

export function orgSettingsUpdated(orgId, settings) {
  return (dispatch, getState) => {
    const { posOrgs } = getState();
    const org = posOrgs.find(i => i.get('id') === orgId).toJS();
    const prefs = { ...org.prefs, ...settings };

    dispatch({ type: POS_ORG_UPDATED, org: { ...org, prefs } });
  };
}

export function mergeOrgSettings(orgId, settings) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/orgs/${orgId}/prefs/`);
    const config = axiosDefault();

    return axios.patch(url, settings, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(orgSettingsUpdated(orgId, settings)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function replaceOrgSettings(orgId, prefs) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/orgs/${orgId}/prefs/`);
    const config = axiosDefault();

    return axios
      .put(url, prefs, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_ORG_UPDATED, org: { id: orgId, prefs } }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchPosOrgs() {
  return dispatch => {
    const url = prefixUrl('/pos/orgs/');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(data => {
        dispatch({
          type: POS_ORGS_FETCHED,
          posOrgs: data.posOrganisations
        });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchContactsByOrgId(orgId) {
  return dispatch => {
    const url = prefixUrl(`/pos/orgs/${orgId}/contacts/`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => dispatch({ type: POS_CONTACTS_FETCHED, contacts: data }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function saveContactInfo(orgId, contactType, data) {
  return dispatch => {
    const url = prefixUrl(`/pos/orgs/${orgId}/contacts/${contactType}`);
    const config = axiosDefault();

    return axios.put(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_CONTACT_UPDATED, contactType, contact: data }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deleteContactInfo(orgId, contactType) {
  return dispatch => {
    const url = prefixUrl(`/pos/orgs/${orgId}/contacts/${contactType}`);
    const config = axiosDefault();

    return axios.delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_CONTACT_UPDATED, contactType, contact: null }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function mergePosUnitPrefs(vunitId, prefs) {
  return (dispatch, getState) => {
    const url = prefixUrl(`/pos/vunits/${vunitId}/prefs`);
    const config = axiosDefault();
    const existingPrefs = getPosUnitPrefsFromVunitId(getState(), { vunitId });
    const newPrefs = { ...existingPrefs, ...prefs };

    return axios.patch(url, prefs, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(posUnitUpdated({ id: vunitId, vunitId, prefs: newPrefs })))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function replacePosUnitPrefs(vunitId, prefs) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/vunits/${vunitId}/prefs`);
    const config = axiosDefault();

    return axios
      .put(url, prefs, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(posUnitUpdated({ id: vunitId, vunitId, prefs })))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createExtPosUnitIfNeeded(posOrg) {
  return (dispatch, getState) => {
    const { posUnits } = getState();
    if (!posUnits.find(p => p.get('type') === 'ExtPayments')) {
      const posUnit = {
        type: 'ExtPayments',
        address: posOrg.get('companyAddress')
      };
      return dispatch(createPosUnit(posOrg.get('id'), posUnit));
    }
    return Promise.resolve();
  };
}

export function createPosUnit(posOrgId, values) {
  return dispatch => {
    const url = prefixUrl(`/pos/vunits/orgs/${posOrgId}/vunits/`);
    const config = axiosDefault();

    return axios
      .post(url, values, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then((res) => {
        const unit = { ...values, id: res.id };
        dispatch({ type: POS_UNIT_CREATED, unit });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function posUnitUpdated(unit) {
  return { type: POS_UNIT_UPDATED, unit };
}

export function posUnitOpened(id) {
  return posUnitUpdated({
    id, vunitId: id, status: 'OpenCurrentDevice', open: true
  });
}

export function posUnitOpenedOther(id, userAgentFields) {
  return posUnitUpdated({
    id, vunitId: id, userAgentFields, status: 'OpenOtherDevice', open: true
  });
}

export function posUnitClosed(id) {
  return posUnitUpdated({
    id, vunitId: id, status: 'Closed', open: false
  });
}

export function posUnitAutoClose(id, autoCloseEnabled, autoCloseTime) {
  return posUnitUpdated({
    id, vunitId: id, autoCloseEnabled, autoCloseTime
  });
}

export function setPosUnitName(id, name) {
  return dispatch => {
    const url = prefixUrl(`/pos/vunits/${id}/set-name`);
    const config = axiosDefault();

    return axios
      .put(url, { name }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(posUnitUpdated({ id, vunitId: id, name })))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function setPosUnitAutoClose(posOrgId, vunitId, enabled, closeTime) {
  return dispatch => {
    const url = prefixUrl(`/pos/orgs/${posOrgId}/vunits/${vunitId}/auto-close`);
    const config = axiosDefault();

    return axios
      .put(url, { enabled, closeTime }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(posUnitAutoClose(vunitId, enabled, closeTime)))
      .catch(error => {
        axiosErrorHandler(error, dispatch);
        throw error;
      });
  };
}

export function openPosUnit(id, cashBalance) {
  return dispatch => {
    const url = prefixUrl(`/pos/vunits/${id}/open`);
    const config = axiosDefault();
    const data = { cashBalance };

    dispatch(setPosProgress(true));
    return axios
      .put(url, data, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(posUnitOpened(id)))
      .catch(error => {
        dispatch(setPosProgress(false));
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function closePosUnit(id, values, force = false) {
  return (dispatch) => {
    const action = force ? 'force-close' : 'close';
    const url = prefixUrl(`/pos/vunits/${id}/${action}`);
    const config = axiosDefault();

    dispatch(setPosProgress(true));
    return axios
      .put(url, { ...values }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(({ data }) => {
        dispatch(posUnitClosed(id));
        dispatch(fetchAvailablePosUnits());
        return data;
      })
      .catch(error => {
        dispatch(setPosProgress(false));
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function switchPosUnitDevice(id) {
  return dispatch => {
    const url = prefixUrl(`/pos/vunits/${id}/switch-device-token`);
    const config = axiosDefault();

    dispatch(setPosProgress(true));
    return axios
      .post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(posUnitOpened(id)))
      .catch(error => {
        dispatch(setPosProgress(false));
        throw error;
      });
  };
}

export function setPosUnitActive(orgId, vunitId, active) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/orgs/${orgId}/vunits/${vunitId}/active?active=${active}`);
    const config = axiosDefault();

    return axios
      .put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(posUnitUpdated({ id: vunitId, vunitId, active })))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deletePosUnit(orgId, vunitId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/orgs/${orgId}/vunits/${vunitId}`);
    const config = axiosDefault();

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_UNIT_DELETED, id: vunitId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchPosUnitsByOrgId(orgId) {
  return dispatch => {
    const url = prefixUrl(`/pos/orgs/${orgId}/vunits/`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ units }) => dispatch({ type: POS_ORG_UNITS_FETCHED, units }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchAllPosUnits() {
  return dispatch => {
    const url = prefixUrl('/pos/vunits/');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ vunits }) => dispatch({ type: POS_ALL_UNITS_FETCHED, vunits }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createPosUnitCcu(id) {
  return dispatch => {
    const url = prefixUrl(`/pos/vunits/${id}/ccu/`);
    const config = axiosDefault();

    return axios
      .post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ ccuRegisterId }) => {
        dispatch(posUnitUpdated({ id, vunitId: id, ccuRegisterId }));
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchCcuInfo(posOrgId, vunitId) {
  return dispatch => {
    const url = prefixUrl(`/pos/orgs/${posOrgId}/vunits/${vunitId}/ccu`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateCcuInfo(posOrgId, vunitId) {
  return dispatch => {
    const url = prefixUrl(`/pos/orgs/${posOrgId}/vunits/${vunitId}/update-ccu`);
    const config = axiosDefault();

    return axios
      .post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function downloadPosUnitSkvReport(vunitIds, start, end) {
  const url = prefixUrl('/pos/skvreport/report-url');
  const config = axiosDefault();

  return (dispatch) => {
    return axios
      .post(url, { vunitIds, start, end }, config)
      .then(res => res.data)
      .then(({ url }) => window.open(url, '_self'));
  };
}

export function downloadReceiptPreview(id) {
  const url = prefixUrl(`/pos/vunits/${id}/receipt-preview`);
  const config = axiosDefault();

  return (dispatch) => {
    return axios
      .post(url, null, config)
      .then(res => res.data)
      .then(({ token }) => {
        const url = prefixUrl(`/receipt-preview/${token}`);
        window.open(url, '_self');
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchTerminalRefData() {
  return dispatch => {
    const url = getTerminalsUrl('/ref-data');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ terminals, vunits }) => {
        dispatch({ type: POS_TERMINAL_REFDATA_FETCHED, terminals, vunits });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchTerminalsByVunitId(vunitId) {
  return dispatch => {
    const url = getTerminalsUrl(`/?vunitId=${vunitId}`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ terminals }) => dispatch({ type: POS_TERMINALS_BY_UNIT_FETCHED, terminals }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function fetchTerminalStatus(terminalId) {
  return dispatch => {
    const url = getTerminalsUrl(`/${terminalId}/status`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(setTerminalStatus(terminalId, res.data)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function updateTerminal(terminal) {
  return dispatch => {
    const url = getTerminalsUrl(`/${terminal.id}/name`);
    const config = axiosDefault();
    const { name } = terminal;

    return axios
      .put(url, { name }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_TERMINAL_UPDATED, terminal }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function addTerminalAssociation({ vunitId, terminalId, merchId }) {
  return (dispatch) => {
    const url = getTerminalsUrl(`/${terminalId}/associate`);
    const config = axiosDefault();

    return axios
      .put(url, { vunitId, merchId }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_TERMINAL_ASSOCIATED, vunitId, merchId, terminalId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function removeTerminalAssociation(terminalId, vunitId) {
  return (dispatch) => {
    const url = getTerminalsUrl(`/${terminalId}/disassociate/${vunitId}`);
    const config = axiosDefault();

    return axios
      .put(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch({ type: POS_TERMINAL_DISASSOCIATED, vunitId }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function terminalPost(path) {
  return (dispatch) => {
    const url = getTerminalsUrl(path);
    const config = axiosDefault();

    return axios
      .post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function setTerminalStatus(vendorId, terminalStatus) {
  return {
    type: POS_TERMINAL_STATUS,
    vendorId,
    terminalStatus
  };
}

export function ensureTerminalConnection(vendorId) {
  return (dispatch) => {
    return dispatch(terminalPost(`/${vendorId}/ensure-connection`))
      .then(res => dispatch(setTerminalStatus(vendorId, res.data)));
  };
}

export function reconcileTerminal(terminalId, posUnitId) {
  return terminalPost(`/${terminalId}/reconcile-for-vunit/${posUnitId}`);
}

export function downloadTerminalDataset(terminalId, posUnitId) {
  return terminalPost(`/${terminalId}/download-dataset-for-vunit/${posUnitId}`);
}

export function upgradeTerminalSoftware(terminalId) {
  return terminalPost(`/${terminalId}/download-software`);
}

export function invalidateTerminalConnection(vendorId) {
  return terminalPost(`/${vendorId}/force-invalidate-poolobject`);
}

export function closeTerminalSocket(vendorId) {
  return terminalPost(`/${vendorId}/force-close-socket`);
}

export function refreshTerminals() {
  return (dispatch) => {
    return dispatch(terminalPost('/refresh'))
      .then(() => dispatch(fetchTerminalRefData()));
  };
}

export function fetchPrinters() {
  return dispatch => {
    const url = prefixUrl('/printers/');
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .then(({ printers }) => dispatch({ type: POS_PRINTERS_FETCHED, printers }))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

function addPrinterDelay() {
  // Wait for printer to poll server
  return new Promise((resolve) => {
    setTimeout(() => resolve(), 2000);
  });
}

export function addPrinter({ macAddress }) {
  return (dispatch) => {
    const url = prefixUrl('/printers/');
    const config = axiosDefault();

    return axios
      .post(url, { MAC: macAddress }, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => addPrinterDelay())
      .then(() => dispatch(fetchPrinters()))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function deletePrinter(macAddress) {
  return (dispatch) => {
    const url = prefixUrl(`/printers/${macAddress}`);
    const config = axiosDefault();

    return axios
      .delete(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => dispatch(fetchPrinters()))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function printTestPage(printerId) {
  return (dispatch, getState) => {
    const url = prefixUrl(`/printers/${printerId}/test-page`);
    const config = axiosDefault();
    const printer = getState().printers.find(p => p.get('id') === printerId);

    return axios
      .post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => dispatch(printerQueued(printer.get('macAddress'))))
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function printerQueued(entityId) {
  return {
    type: POS_PRINTER_QUEUED,
    macAddress: entityId
  };
}

export function printerProgress(entityId, status) {
  return {
    type: POS_PRINTER_PROGRESS,
    macAddress: entityId,
    status
  };
}

export function printerDone(entityId, status) {
  return {
    type: POS_PRINTER_DONE,
    macAddress: entityId,
    status
  };
}

export function printerStatus(entityId, status) {
  return {
    type: POS_PRINTER_STATUS,
    macAddress: entityId,
    status
  };
}

export function fetchStripeState(vunitId) {
  return dispatch => {
    const url = prefixUrl(`/stripe/auth/pos/vunit/${vunitId}/state`);
    const config = axiosDefault();

    return axios
      .get(url, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function testSwishIntegration(posOrgId) {
  return dispatch => {
    const url = prefixUrl(`/pos/swish/check-swish-for-posorg/${posOrgId}`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data);
  };
}
