import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSysAdmin } from '@State/selectors';
import {
  closeTerminalSocket, downloadTerminalDataset, ensureTerminalConnection, fetchTerminalStatus,
  invalidateTerminalConnection, removeTerminalAssociation, upgradeTerminalSoftware
} from '@State/pos-config-actions';
import { Button } from '@Components/ui/styled/main';
import ProgressButton from '@Components/ui/progress-button';
import Popover from '@Components/ui/popover';

const TerminalTools = ({ terminal, vunitId }) => {
  const dispatch = useDispatch();
  const isSysAdmin = useSelector(getSysAdmin);
  const [showTools, setShowTools] = useState(false);
  const terminalId = terminal.get('id');
  const vendorId = terminal.get('vendorId');

  const handleEnsureConnection = () => dispatch(ensureTerminalConnection(vendorId));
  const handleDownloadDataset = () => dispatch(downloadTerminalDataset(terminalId, vunitId));
  const handleUpgradeSoftware = () => dispatch(upgradeTerminalSoftware(terminalId));
  const handleInvalidateConnection = () => dispatch(invalidateTerminalConnection(vendorId));
  const handleCloseSocket = () => dispatch(closeTerminalSocket(vendorId));
  const handleDisassociate = () => dispatch(removeTerminalAssociation(terminalId, vunitId));

  const toolsPopoverBody = (
    <div className="Popover-dialog-sm button-wrapper">
      <div className="menu-button-wrapper">
        <ProgressButton small block gray onClick={handleEnsureConnection}>Kontrollera anslutning</ProgressButton>
        {vunitId && <ProgressButton small block gray onClick={handleDownloadDataset}>Ladda ner dataset</ProgressButton>}
        <ProgressButton small block gray onClick={handleUpgradeSoftware}>Uppgradera mjukvara</ProgressButton>
        <ProgressButton small block danger onClick={handleCloseSocket}>Close websocket</ProgressButton>
        <ProgressButton small block danger onClick={handleInvalidateConnection}>Invalidate pool</ProgressButton>
        {vunitId && <ProgressButton small block danger marginTop onClick={handleDisassociate}>Koppla bort från kassa</ProgressButton>}
      </div>
    </div>
  );

  return isSysAdmin ? (
    <Popover
      preferPlace="below"
      isOpen={showTools}
      onOuterAction={() => setShowTools(false)}
      body={toolsPopoverBody}
      className="Popover-big-radius"
    >
      <Button outlinePrimary small marginLeft onClick={() => setShowTools(!showTools)}>
        <i className="far fa-cog" />
      </Button>
    </Popover>
  ) : null;
};

export default TerminalTools;
