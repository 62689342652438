import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleServicesCollapsed } from '@State/admin-view-reducer';
import {
  updateServiceGroup, deleteServiceGroup, moveService, moveGroup
} from '@State/services-actions';
import { getFilteredServiceGroups, getFilteredServices, getIsAddonServicesSection } from '@Utils/filter-selectors';
import { getServiceConfigUrl, navigate } from '@Utils/navigate';
import { getDurationAndPrice } from '@Utils/service-util';
import SortableList from '../sortable-list/sortable-list';

class ServiceList extends Component {
  static propTypes = {
    filteredServiceGroups: PropTypes.object.isRequired,
    filteredServices: PropTypes.object.isRequired,
    selectedId: PropTypes.number,
    moveService: PropTypes.func.isRequired,
    moveGroup: PropTypes.func.isRequired,
    updateServiceGroup: PropTypes.func.isRequired,
    deleteServiceGroup: PropTypes.func.isRequired,
    sortable: PropTypes.bool,
    routeParams: PropTypes.object.isRequired
  };

  render() {
    const {
      filteredServiceGroups, filteredServices, selectedId, sortable,
      collapsedIds, onToggleCollapse, isAddonServices
    } = this.props;
    const {
      moveService, moveGroup, updateServiceGroup, deleteServiceGroup
    } = this.props;

    return (
      <SortableList
        onChangeCollapsedIds={onToggleCollapse}
        collapsedIds={collapsedIds}
        groups={filteredServiceGroups}
        groupItemSelector="serviceIds"
        sortItemSelector={isAddonServices ? 'name' : null}
        items={filteredServices}
        itemContent={this.itemContent}
        selectedId={selectedId}
        onItemClick={this.onItemClick}
        updateGroup={updateServiceGroup}
        deleteGroup={deleteServiceGroup}
        moveGroup={moveGroup}
        moveItem={moveService}
        sortable={sortable}
        hideGroupMenu={isAddonServices}
      />
    );
  }

  onItemClick = item => {
    navigate(getServiceConfigUrl(this.props.routeParams, item.id, this.props.routeParams.tab));
  };

  itemContent = item => {
    const { name, bookingType, prefs } = item;
    const color = prefs?.colorway;
    const isClass = bookingType === 'ClassBooking';
    const description = getDurationAndPrice(item);

    return (
      <div>
        {color && <span className="columns-list-color-dot" style={{ background: color }} />}
        <span className="list-item-header">{name}</span>
        {isClass && <div className="list-item-sub-header"><i className="fa fa-users" /></div>}

        <br />
        <span className="list-item-subtext">
          {description}
        </span>
      </div>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const { adminViewState } = state;

  return {
    collapsedIds: adminViewState.get('servicesCollapsedIds'),
    filteredServices: getFilteredServices(state, ownProps),
    filteredServiceGroups: getFilteredServiceGroups(state, ownProps),
    isAddonServices: getIsAddonServicesSection(state, ownProps)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleCollapse: (collapsedIds) => {
      return dispatch(toggleServicesCollapsed(collapsedIds));
    },
    moveService: moveAction => {
      return dispatch(moveService(moveAction));
    },
    moveGroup: moveAction => {
      return dispatch(moveGroup(moveAction));
    },
    updateServiceGroup: group => {
      return dispatch(updateServiceGroup(group));
    },
    deleteServiceGroup: groupId => {
      return dispatch(deleteServiceGroup(groupId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceList);
