export const resourceTypes = {
  Person: 'Person',
  Equipment: 'Utrustning',
  Room: 'Rum'
};

export const resourceStaffTypes = {
  Employee: 'Anställd - av företaget som äger kontot',
  Freelance: 'Frilans - t.ex. eget företag eller hyrstol'
};

export function getResourceIcon(resourceType) {
  switch (resourceType) {
    case 'Equipment':
      return 'fa fa-screwdriver-wrench';
    case 'Room':
      return 'fa fa-booth-curtain';
    default:
      return 'fa fa-user-tie';
  }
}
