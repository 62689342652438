import React, { useEffect, useMemo, useState } from 'react';
import { destroy } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateBookingSeriesRecurrence } from '@State/bkf/actions';
import Popover from '@Components/ui/popover';
import Button from '@Components/ui/button';
import { getInitialValuesFromRruleString, getRruleStringFromForm } from './recurring-util';
import BookingRecurringForm from './booking-recurring-form';

const ChangeRecurrencePopover = ({ seriesId, recurrenceRule }) => {
  const dispatch = useDispatch();
  const bookingId = useSelector(state => state.bkf.get('id'));
  const services = useSelector(state => state.bkf.get('services'));
  const [open, setOpen] = useState(false);

  const initialValues = useMemo(() => {
    return getInitialValuesFromRruleString(recurrenceRule);
  }, [recurrenceRule]);

  useEffect(() => {
    return () => {
      dispatch(destroy('bkf-recurring'));
    };
  }, []);

  const onConfirm = (values) => {
    const recurrenceRule = getRruleStringFromForm(values);
    return dispatch(updateBookingSeriesRecurrence(bookingId, seriesId, recurrenceRule))
      .then(() => setOpen(false));
  };

  const popoverBody = (
    <div className="Popover-dialog Popover-dialog-big">
      <BookingRecurringForm
        onSubmit={onConfirm}
        onClose={() => setOpen(false)}
        initialValues={initialValues}
      />
    </div>
  );

  return (
    <Popover
      className="Popover-big-radius"
      preferPlace="below"
      isOpen={open}
      onOuterAction={() => setOpen(false)}
      body={popoverBody}
    >
      <Button tiny gray marginLeft onClick={() => setOpen(true)}>Ändra</Button>
    </Popover>
  );
};

export default ChangeRecurrencePopover;
